import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Table, TableHeader, TableBody, TableRow, TableCell, TableHead, Tag } from 'bold-ui';
import { TagDone, TagNormal } from '../../../components/Tags';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}>{`Overview`}</h1>
    <p>{`Track the status of components across design and development. You can also see new components and the next ones to come.`}</p>
    <h2 {...{
      "id": "component-status",
      "style": {
        "position": "relative"
      }
    }}>{`Component status`}</h2>
    <Table mdxType="Table">
      <TableHead mdxType="TableHead">
  <TableRow mdxType="TableRow">
    <TableHeader mdxType="TableHeader">Component</TableHeader>
    <TableHeader mdxType="TableHeader">Design</TableHeader>
    <TableHeader mdxType="TableHeader">Development</TableHeader>
  </TableRow>
      </TableHead>
      <TableBody mdxType="TableBody">
    <TableRow mdxType="TableRow">
        <TableCell mdxType="TableCell">Accordion</TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
        <TableCell mdxType="TableCell"><TagNormal mdxType="TagNormal">Upcoming</TagNormal></TableCell>
     </TableRow>
     <TableRow mdxType="TableRow">
        <TableCell mdxType="TableCell">Alert</TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
     </TableRow>
     <TableRow mdxType="TableRow">
        <TableCell mdxType="TableCell">Breadcrumb</TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
     </TableRow>
     <TableRow mdxType="TableRow">
        <TableCell mdxType="TableCell">Button</TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
     </TableRow>
     <TableRow mdxType="TableRow">
        <TableCell mdxType="TableCell">Cards</TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
        <TableCell mdxType="TableCell"><TagNormal mdxType="TagNormal">Upcoming</TagNormal></TableCell>
     </TableRow>
     <TableRow mdxType="TableRow">
        <TableCell mdxType="TableCell">Checkbox</TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
     </TableRow>
     <TableRow mdxType="TableRow">
        <TableCell mdxType="TableCell">Content Switch</TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
     </TableRow>
    <TableRow mdxType="TableRow">
        <TableCell mdxType="TableCell">Date Picker</TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
     </TableRow>
    <TableRow mdxType="TableRow">
        <TableCell mdxType="TableCell">Dropdown</TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
     </TableRow>
    <TableRow mdxType="TableRow">
        <TableCell mdxType="TableCell">File Uploader</TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
     </TableRow>
    <TableRow mdxType="TableRow">
        <TableCell mdxType="TableCell">Form</TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
     </TableRow>
    <TableRow mdxType="TableRow">
        <TableCell mdxType="TableCell">Link</TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
     </TableRow>
    <TableRow mdxType="TableRow">
        <TableCell mdxType="TableCell">Modal</TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
     </TableRow>
    <TableRow mdxType="TableRow">
        <TableCell mdxType="TableCell">Multi Select</TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
     </TableRow>
    <TableRow mdxType="TableRow">
        <TableCell mdxType="TableCell">Paginator</TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
     </TableRow>
     <TableRow mdxType="TableRow">
        <TableCell mdxType="TableCell">Form</TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
        <TableCell mdxType="TableCell">Link</TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
        <TableCell mdxType="TableCell">Modal</TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
        <TableCell mdxType="TableCell">Multi Select</TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
        <TableCell mdxType="TableCell">Paginator</TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
        <TableCell mdxType="TableCell">Radio Button</TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
        <TableCell mdxType="TableCell">Search</TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
        <TableCell mdxType="TableCell">Select</TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
        <TableCell mdxType="TableCell">Stepper</TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
        <TableCell mdxType="TableCell">Switch</TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
        <TableCell mdxType="TableCell">Table</TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
        <TableCell mdxType="TableCell">Tabs</TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
        <TableCell mdxType="TableCell">Tag</TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
        <TableCell mdxType="TableCell">Text Field</TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
        <TableCell mdxType="TableCell">Toast</TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
        <TableCell mdxType="TableCell"><TagNormal mdxType="TagNormal">Upcoming</TagNormal></TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
        <TableCell mdxType="TableCell">Tooltip</TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
        <TableCell mdxType="TableCell"><TagDone mdxType="TagDone">Done</TagDone></TableCell>
    </TableRow>
      </TableBody>
    </Table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      